import { CartType } from '@actions/ecommerce/ecom-cart-base-types';
import { z } from 'zod';

enum CGM_CADENCE {
  /** Only a single box of sensors */
  ADHOC = 'adhoc',
  /** Continuous sensor data schedule. Right now every 28 days for Abbott (2x 14-day sensors) */
  CONTINUOUS = 'continuous',
  /** One new CGM kit every 2 months */
  BIMONTHLY = 'bi-monthly',
  /** One new CGM kit every 3 months */
  QUARTERLY = 'quarterly',
  /**
   * @deprecated: Stopped try-it experiment
   * Only a single CGM sensor */
  TRYIT = 'tryit',
}

export const CgmCadence = z.nativeEnum(CGM_CADENCE);
export type CgmCadence = z.infer<typeof CgmCadence>;

enum CGM_TYPE {
  /** Dexcom G6 "realtime" sensor, on IRB path */
  DEXCOM_G6 = 'dexcom',
  /** Dexcom G7 "realtime" sensor, on IRB path */
  DEXCOM_G7 = 'dexcom_g7',
  /** Dexcom G7 "realtime" sensor, consult only, on Rx path */
  DEXCOM_G7_CONSULT = 'dexcom_g7_consult',
  /** Abbott Libre 14 day sensor, aka "manual" */
  ABBOTT_FSL_14DAY = 'abbott',
  /** Abbott Libre 3 sensor, "realtime" from Abbott */
  ABBOTT_LIBRE3 = 'abbott_libre3',
  /** No cgm, only levels membership */
  NO_CGM = 'no_cgm',
  /**
   * @deprecated
   * Only a single CGM sensor */
  TRYIT = 'tryit',
}

export const CgmType = z.nativeEnum(CGM_TYPE);
export type CgmType = z.infer<typeof CgmType>;

export const isCgmRequired = (cartType: CartType) => {
  switch (cartType) {
    case CartType.enum.GB_NEW_USER_SIGNUP:
    case CartType.enum.US_TRYIT_USER_SIGNUP:
      return true;
    case CartType.enum.US_LABS:
    case CartType.enum.US_BETA2_USER_SIGNUP:
    case CartType.enum.US_NEW_USER_SIGNUP:
      return false;
  }
};
