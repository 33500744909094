import { DexcomBoltOnItem } from '@actions/order/bolt-on-constants';

export enum Prices {
  Membership = 199,
  CGM = 199,
  Total = 398,
  MonthlyMembership = 10,
}

/** type non-Dexcom subscription options */
export type NonIrbSubscription = {
  id: string;
  title: string;
};

/** enumerate Libre subscription options. For if a new member opts out of the study. */
export const nonIrbSubscriptionOptions: NonIrbSubscription[] = [
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-continuous',
    title: 'Every month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-bimonthly',
    title: 'Every other month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-quarterly',
    title: 'Every third month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-adhoc',
    title: 'No subscription, just a single CGM kit',
  },
];

/** enumerate Libre subscription options with alternate copy for A/B testing. */
export const nonIrbSubscriptionOptionsAltCopy: NonIrbSubscription[] = [
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-continuous',
    title: 'Every month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-bimonthly',
    title: 'Every other month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-quarterly',
    title: 'Every third month',
  },
  {
    id: 'cgm-us-rx-abbott-freestyle_libre-adhoc',
    title: 'No subscription, just a single CGM kit',
  },
];

/** type IRB Dexcom purchase subscription options */
export type DexcomIrbSubscription = {
  id: DexcomBoltOnItem;
  title: string;
};

/** enumerate Dexcom purchase subscription options. For if a new member opts into the study. */
export const subscriptionOptions: DexcomIrbSubscription[] = [
  {
    id: 'cgm-us-irb-dexcom-g6-continuous',
    title: 'Every month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-bimonthly',
    title: 'Every other month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-quarterly',
    title: 'Every third month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-adhoc',
    title: 'No subscription, just a single CGM kit',
  },
];

/** enumerate Dexcom subscription options with alternate copy for A/B testing. */
export const subscriptionOptionsAltCopy: DexcomIrbSubscription[] = [
  {
    id: 'cgm-us-irb-dexcom-g6-continuous',
    title: 'Every month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-bimonthly',
    title: 'Every other month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-quarterly',
    title: 'Every third month',
  },
  {
    id: 'cgm-us-irb-dexcom-g6-adhoc',
    title: 'No subscription, just a single CGM kit',
  },
];
