import { CgmCadence, CgmType } from '@actions/ecommerce/ecom-cgm-base-types';
import { Prices } from 'components/form/content/Purchase';
import Link from 'next/link';
import DeliverIcon from 'public/assets/sensor-selection/deliverIcon.svg';
import MoneyBackIcon from 'public/assets/sensor-selection/moneyBackIcon.svg';
import PauseCancelIcon from 'public/assets/sensor-selection/pauseCancelIcon.svg';
import ShippingIcon from 'public/assets/sensor-selection/shippingIcon.svg';
import { FC } from 'react';

/** Use it for data-attr naming. The formula is page-name.input-name.input-value. For example 'signup-3-1.eligible.diabetes_1' */
export const POSTHOG_PAGES = Object.freeze({
  SIGNUP_3_1: 'signup-3-1',
  SIGNUP_3_2: 'signup-3-2',
  SIGNUP_3_5: 'signup-3-5',
  SIGNUP_SMOKE_TEST_1: 'signup-smoke-test-1',
  SIGNUP_SMOKE_TEST_2: 'signup-smoke-test-2',
  HEADER: 'header', // use it for Header multipage component
  HOME: 'home',
  ACCOUNT: 'account',
  PROFILE: 'profile',
  HOMEPAGE: 'homepage',
});

export const homePageUrl = 'https://www.levelshealth.com';

export const questions = [
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.FAQ.why-charge`,
    title: 'Why does Levels charge a Membership fee?',
    text: 'The annual fee allows members to access CGMs and the Levels app, and enables Levels to make consistent improvements for software features, educational content, and infrastructure to offer CGMs and to help our members achieve metabolic health-related goals.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.FAQ.what-included`,
    title: 'What’s included in my membership?',
    text: 'Levels Members get unlimited access to the Levels app, at or near cost CGMs, and our active community to get personalized feedback and insights to help you in your metabolic health journey.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.FAQ.when-start`,
    title: 'When will my membership start?',
    text: 'Your membership starts after you sign up, pending approval for your CGM prescription by a licensed clinician.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.FAQ.cancel-membership`,
    title: 'Can I cancel my membership?',
    text: 'You can cancel at any time. You will still be able to access your membership until your cancelled membership expires.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.FAQ.own-cgm`,
    title: 'Can I use my own CGM with the Levels membership?',
    text: 'We don’t currently have an app-only offering where you can use Levels with an existing CGM you obtained elsewhere.',
  },
];

export const updatedQuestions = [
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.app-subscription`,
    title: "What's included in my app subscription?",
    text: 'Your Levels app subscription provides full and unrestricted access to the Levels app and all its features, plus access to state-of-the-art Continuous Glucose Monitors (CGM).',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.why-annual`,
    title: 'Why an annual subscription?',
    text: 'Whether your health goal is managing weight, avoiding disease, or simply feeling better every day, you need time to build the habits that work for you. Quick fixes don’t stick, and one-size-fits-all advice rarely works. Over the course of a year, you can use the insights from your personal data—glucose, meal logs, exercise, sleep—to build a sustainable plan for reaching your goals. And with our long-term trend tracking, you can see real changes in your biomarkers that show your efforts are working.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.cancel-membership`,
    title: 'Can I cancel my app subscription?',
    text: 'Yes, you can cancel your app subscription at any time. You’ll still be able to access the Levels app until the end of your current billing period.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.prescription`,
    title: 'Do I need a prescription for a CGM?',
    text: "Depending on your choice of CGM, you may need a prescription, but we can take care of that for you. We'll ask you a few questions about your health, and a licensed physician will review your information to check you are eligible. If you’re approved, we'll handle your prescription and ship your CGM within 3-5 business days.",
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.change-cgm`,
    title: 'Can I change which CGM I use?',
    text: 'Yes, you can switch, pause or cancel your CGM plan whenever you want.',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.own-cgm`,
    title: 'Can I use my own CGM with Levels?',
    text: 'Not just yet. But we’re working on making Levels work with a CGM purchased elsewhere. ',
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.wear-cgm`,
    title: 'Do I need to wear a CGM?',
    text: (
      <>
        <p>
          Although wearing a CGM unlocks the full benefits of the Levels app, you don’t need to wear a CGM continuously for Levels to help
          you improve your health.
        </p>
        <p className="mt-5">Levels recommends using a CGM at least every quarter to check-in on your progress.</p>
      </>
    ),
  },
  {
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_2}.FAQ.insurance-coverage`,
    title: 'Is Levels covered by my insurance?',
    text: 'No, Levels is not eligible for payment through insurance, Health Savings Accounts (HSAs), or Flexible Spending Accounts (FSAs). Making Levels accessible to as many people as possible is a top priority for us, and we’re working hard to make Levels affordable no matter what your insurance status is.',
  },
];

export const offers = [
  {
    title: 'Unlimited access to the Levels App',
    text: 'Personalized health insights, exclusive member content, and Continuous Glucose Monitors – whenever you need them.',
    imgSrc: '/assets/v2-purchase/levels-phone.jpg',
  },
  {
    title: 'Personalized health insights 24/7',
    text: 'Real-time insights to help you monitor and optimize your diet, exercise, stress levels, and sleep.',
    imgSrc: '/assets/v2-purchase/levels-park-v2.jpg',
  },
  {
    title: 'Progress reports in your inbox',
    text: 'Get regular Metabolic Reports to monitor trends and help you stay on track with your metabolic health journey.',
    imgSrc: '/assets/v2-purchase/levels-man-phone.jpg',
  },
  {
    title: 'All your health data in one place',
    text: 'Import your health data to understand the relationship between your lifestyle choices and blood glucose.',
    imgSrc: '/assets/v2-purchase/levels-food.jpg',
  },
  {
    title: 'Access to our growing community ',
    text: 'Participate in community experiments, join exclusive Q&As with thought leaders, and discover healthy recipes and foods.',
    imgSrc: '/assets/v2-purchase/levels-wall.jpg',
  },
  {
    title: 'CGMs delivered to your door',
    text: 'Convenient access to CGMs, whenever you need them. You can pause, delay or cancel your subscription anytime.',
    imgSrc: '/assets/v2-purchase/levels-box-phone.jpg',
  },
];

// Organized in order of preference if software only is available it will be the default selected option. If it's now available then G6 will be the default selected option.
export const planPricesB = [
  {
    value: CgmType.enum.NO_CGM,
    title: 'Levels Software Only',
    text: 'Access to the Levels app for 12 months, CGMs not included',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_5}.sensor-plan.levelsmembership`,
    selectedContent: (
      <p className="text-btn-green-bg font-normal">
        <span className={'font-bold'}>Get the Levels Software</span>.
      </p>
    ),
    image: '/assets/v2-purchase/levels-logo-upscaled.webp',
  },
  {
    value: CgmType.enum.DEXCOM_G6,
    title: 'Dexcom G6 CGM',
    text: 'Automatically transmit readings to your smartphone',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_5}.sensor-plan.${CgmType.enum.DEXCOM_G6}`,
    selectedContent: (
      <p className="text-btn-green-bg font-normal">
        <span className={'font-bold'}>Dexcom G6 CGM</span> is only approved for use as part of our research study. You will be asked to
        enroll in the study following payment.
      </p>
    ),
    badgeText: 'Most Popular',
    image: '/assets/sensor-selection/DexcomSensorMock.webp',
  },
  {
    value: CgmType.enum.DEXCOM_G7_CONSULT,
    title: 'Real-time Streaming CGM, purchased separately',
    text: 'Dexcom G7 Fulfilled through Amazon Pharmacy',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_5}.sensor-plan.${CgmType.enum.DEXCOM_G7_CONSULT}`,
    selectedContent: (
      <p className="text-btn-green-bg font-bold text-[15px]">
        Medical consultation required <span className="font-normal italic"> – completed after payment </span>
      </p>
    ),
    image: '/assets/how-it-works/cgm.webp',
  },
  {
    value: CgmType.enum.ABBOTT_FSL_14DAY,
    title: 'Freestyle Libre CGM',
    text: 'Tap your phone against the sensor to take a reading',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_5}.sensor-plan.${CgmType.enum.ABBOTT_FSL_14DAY}`,
    selectedContent: (
      <p className="text-btn-green-bg font-normal">
        <span className={'font-bold'}>Freestyle Libre CGM</span> requires a telehealth evaluation from a licensed physician. We’ll guide you
        through this following payment.
      </p>
    ),
    image: '/assets/sensor-selection/LibreSensorMock.webp',
  },
  {
    value: CgmType.enum.ABBOTT_LIBRE3,
    title: 'Freestyle Libre 3 CGM',
    text: 'Automatically transmit readings to your smartphone',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_5}.sensor-plan.${CgmType.enum.ABBOTT_LIBRE3}`,
    selectedContent: (
      <p className="text-btn-green-bg font-normal">
        <span className={'font-bold'}>Freestyle Libre 3 CGM</span> requires a telehealth evaluation from a licensed physician. We’ll guide
        you through this following payment.
      </p>
    ),
    image: '/assets/sensor-selection/LibreSensorMock.webp',
  },
];

export const eligibilityItems = [
  {
    label: 'I have Type 1 diabetes',
    value: 'ineligible_diabetes_1',
  },
  {
    label: 'I have Type 2 diabetes and take medication other than metformin or pioglitazone',
    value: 'ineligible_diabetes_2',
  },
  {
    label: 'I take exogenous insulin',
    value: 'ineligible_insulin',
  },
  {
    label: 'I have an allergy to medical adhesive',
    value: 'ineligible_adhesives',
  },
  {
    label: 'I live outside the US',
    value: 'ineligible_international',
  },
  {
    label: 'I am incarcerated or in legal custody',
    value: 'ineligible_incarcerated',
  },
  {
    label: 'None of these apply to me',
    value: 'eligible',
  },
];

export const studyConsentItems = [
  'Your data is anonymous',
  'You can opt out at any time',
  'No additional action is required',
  'No follow-ups or questionnaires required',
];

export const subscriptionCards = [
  {
    value: CgmCadence.enum.CONTINUOUS,
    title: 'Monthly',
    text: 'Delivered every month',
    dataTestId: 'signup-set-subscription-continuous-card',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.CONTINUOUS}`,
  },
  {
    value: CgmCadence.enum.BIMONTHLY,
    title: 'Bi-Monthly',
    text: 'Delivered every 2 months',
    dataTestId: 'signup-set-subscription-bi-monthly-card',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.BIMONTHLY}`,
  },
  {
    value: CgmCadence.enum.QUARTERLY,
    title: 'Quarterly',
    text: 'Delivered every 3 months',
    dataTestId: 'signup-set-subscription-quarterly-card',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.QUARTERLY}`,
  },
];

export const subscriptionIntervalCards = [
  {
    value: CgmCadence.enum.CONTINUOUS,
    title: (
      <>
        Monthly<span className="text-[15px] font-normal"> • $199 every month</span>
      </>
    ),
    text: '',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.CONTINUOUS}`,
  },
  {
    value: CgmCadence.enum.BIMONTHLY,
    title: (
      <>
        Bimonthly<span className="text-[15px] font-normal"> • $199 every 2 months</span>
      </>
    ),
    text: '',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.BIMONTHLY}`,
  },
  {
    value: CgmCadence.enum.QUARTERLY,
    title: (
      <>
        Quarterly<span className="text-[15px] font-normal"> • $199 every 3 months</span>
      </>
    ),
    text: '',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.QUARTERLY}`,
  },
  {
    value: CgmCadence.enum.ADHOC,
    title: (
      <>
        One Time Purchase<span className="text-[15px] font-normal"> • $199 only</span>
      </>
    ),
    text: '',
    dataAttr: `${POSTHOG_PAGES.SIGNUP_3_1}.subscription.${CgmCadence.enum.ADHOC}`,
  },
];

export const cgmCadenceFrequencyMap = {
  [CgmCadence.enum.CONTINUOUS]: 'month',
  [CgmCadence.enum.BIMONTHLY]: '2 months',
  [CgmCadence.enum.QUARTERLY]: '3 months',
};

export const joinList = [
  'Track your blood glucose in real time',
  'Learn how food and lifestyle affect your health',
  'Develop healthy habits, for life',
];

export const priceCards = [
  {
    title: 'Levels Membership',
    price: Prices.Membership,
    priceInterval: '/year',
    list: ['12-month membership', 'Unlimited Levels App access', 'Personalised health insights'],
    optional: false,
  },
  {
    title: 'Continuous Glucose Monitor',
    price: Prices.CGM,
    priceInterval: '/kit',
    list: ['1-month supply of sensors', 'CGM subscription available', 'Free shipping'],
    optional: true,
  },
];

export const howItWorksCards = [
  {
    title: (
      <>
        Become a<br /> Levels Member
      </>
    ),
    description: 'Get unlimited access to the Levels App, exclusive content, and our growing community of health experts.',
  },
  {
    title: (
      <>
        Choose a CGM
        <br /> subscription plan
      </>
    ),
    description: 'Choose between two state-of-the-art continuous glucose monitors and tell us how often you will need a new kit.',
  },
  {
    title: (
      <>
        Track your blood
        <br /> glucose levels
      </>
    ),
    description: 'Connect your CGM and smartphone to begin tracking how your food and lifestyle choices are affecting your health.',
  },
  {
    title: (
      <>
        Unlock personalized
        <br /> health insights
      </>
    ),
    description: 'Build long-lasting, healthy habits with personalised health insights and recommendations, tailored to your goals.',
  },
];

export const howItWorksContent = [
  {
    title: 'Track your blood glucose, diet and lifestyle, all in one place.',
    image: '/assets/how-it-works/run-woman.webp',
    list: [
      <>
        <span>Continuous glucose monitors</span> track your blood glucose levels in real-time and sync data direct to your smartphone.
      </>,
      <>
        <span> Food logging</span> makes it easy to record what you eat and saves you time, while Levels does the hard work for you.
      </>,
      <>
        <span>Sync data from activity trackers</span> so you get the full picture on how your diet and lifestyle affect your glucose
        stability.
      </>,
      <>
        <span>On-demand blood tests</span> go deeper into the science of metabolism and track key metabolic health trends over time.
      </>,
    ],
  },
  {
    title: 'Understand how food and lifestyle affects your health',
    image: '/assets/how-it-works/healthy-food.webp',
    list: [
      <>
        <span>Glucose spike detection</span> finds opportunities for diet optimization, making it easier to achieve more stable glucose.
      </>,
      <>
        <span>Meal scores</span> make it simple to understand how food affects your glucose and identify which foods are holding you back.
      </>,
      <>
        <span>Personalized insights</span> recommend actionable steps for hitting your goals, like food swaps that don’t spike glucose.
      </>,
      <>
        <span>Powerful reporting</span> closes the loop on how you’re trending via daily, weekly, and monthly reports.
      </>,
    ],
  },
  {
    title: 'Build lasting, healthy habits with personalised guidance',
    image: '/assets/how-it-works/stretch-man.webp',
    list: [
      <>
        <span>Daily personalized checklists</span> provide a simple to follow plan for achieving your metabolic health goals.
      </>,
      <>
        <span>Build a meal catalog</span> of food that works for you, based on your personal and unique glucose response.
      </>,
      <>
        <span>Stability scores</span> help you stay on track and earn streaks which show how you’re making progress day over day.
      </>,
      <>
        <span>Interactive metabolic education</span> makes understanding key metabolic concepts fun and simple.
      </>,
    ],
  },
];

export const cgmDescription = [
  'A continuous glucose monitor (CGM) is a small sensor that sits on your arm and tracks your blood glucose levels throughout the day. You can use a CGM to see how food and lifestyle choices affect your glucose in real time, and can help you make more informed decisions based on what works for you.',
  'Each CGM stays in place for up to 14 days, covered by a protective patch, and can be worn in water, while flying, and during exercise. You connect your CGM with a compatible smartphone and sync blood glucose data to the Levels app.',
  'Continuous glucose monitors have historically only been available through a medical prescription, but Levels can take care of that, if required.',
];

export const orderInformationIconsData = [
  { icon: <DeliverIcon />, text: 'Delivered Monthly' },
  {
    icon: <ShippingIcon />,
    text: (
      <>
        Free
        <br /> Shipping
      </>
    ),
  },
  { icon: <MoneyBackIcon />, text: 'Money Back Guarantee' },
  { icon: <PauseCancelIcon />, text: 'Pause or Cancel Anytime' },
];

export const agreeLabel = (
  <span>
    I agree to the{' '}
    <Link href="/terms">
      <a className="underline font-semibold" target="_blank">
        Terms of Service
      </a>
    </Link>
    {', '}
    <Link href="https://support.levelshealth.com/article/43-refund-policy">
      <a className="underline font-semibold" target="_blank">
        Refund Policy
      </a>
    </Link>{' '}
    and{' '}
    <Link href="/privacy">
      <a className="underline font-semibold" target="_blank">
        Privacy Policy
      </a>
    </Link>
  </span>
);

export const socialProofContent = [
  {
    nameAndDate: 'by Dhjsjsks – Oct 16, 2022',
    title: 'Transformative app',
    text: 'Levels has transformed my diet and I’ve never felt better. I have more energy and less stress since I’ve started avoiding glucose-spiking foods. And the app keeps getting better and better!',
  },
  {
    nameAndDate: 'by JAKRG710 – Jan 27, 2023',
    title: 'I love this product',
    text: 'I have been concerned about future diabetes forever. Now I can understand how to manage my blood glucose. This is the most important part of my health regime.',
  },
  {
    nameAndDate: 'by npatel81 – Oct 20, 2022',
    title: 'Real-time insight into your health!',
    text: 'This app is truly invaluable! If you’re looking to get a comprehensive view of how your food, sleep and exercise affect your health this is a must have! Levels truly simplifies what seems complicated and guides you to better health.',
  },
  {
    nameAndDate: 'by alibellows – Oct 15, 2022',
    title: 'Game-changer for your health',
    text: 'The most impactful health tool I’ve tried. Somehow Levels makes your metabolism (highly complex and individual) understandable. The app is intuitive and improves quickly!',
  },
];

export const Subheading: FC = ({ children }) => <p className={'font-bold text-black-title uppercase text-lg mb-2'}>{children}</p>;
