import { z } from 'zod';

export enum CART_TYPE {
  /** New user signup cart for United Kingdom */
  GB_NEW_USER_SIGNUP = 'gb_new_user_signup',
  /** New user signup cart for United States */
  US_NEW_USER_SIGNUP = 'us_new_user_signup',
  /**
   * @deprecated: No more beta users
   * New user monthly signup cart for United States */
  US_BETA2_USER_SIGNUP = 'us_beta2_user_signup',
  /** Labs purchase for United States */
  US_LABS = 'us_labs',
  /**
   * @deprecated: Stopped try-it experiment
   * New US user signup cart for the Try It program */
  US_TRYIT_USER_SIGNUP = 'us_tryit_user_signup',
}

export const CartType = z.nativeEnum(CART_TYPE);
/** Zod type for {@link CART_TYPE} enum */
export type CartType = z.infer<typeof CartType>;
